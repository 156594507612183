import React,{useState,useEffect,useRef} from 'react';
import axios from 'axios';
import NumericQuestion from '../components/NumericQuestion';
import CommentQuestion from '../components/CommentQuestion';
import YesNoQuestion from '../components/YesNoQuestion';
import Spinner from '../components/Spinner';
import ProjectInfo from '../components/ProjectInfo';
import { useParams } from 'react-router-dom';
import config from '../config.json';
import $ from 'jquery';

export default function SampleSurvey(props) {
    const mode = props.mode;
    const [loading,setLoading] = useState(true);
    const [numericQuestions,setNumericQuestions] = useState([]);
    const [commentQuestions,setCommentQuestions] = useState([]);
    const [yesNoQuestions,setYesNoQuestions] = useState([]);
    const [ynNumericQuestions,setYNNumericQuestions] = useState([]);
    const [yesNoQuestionsSectionTwo,setYesNoQuestionsSectionTwo] = useState([]);
    const [complete, setComplete] = useState(false);
    const [completed,setCompleted] = useState(false);
    const [noData, setNoData] = useState(false);
    const [finalAnswers, setFinalAnswers] = useState({});
    const [csdId, setCsdId] = useState(null);
    const [contactValues,setContactValues] = useState([]);
    const [alerted,setAlerted] = useState(false);
    const answers = {};
    var refs = useRef({});
    const {id} = useParams();
    var prevMissing = [];
    const serverURL = config.serverURL;

    const initData = async function(){
        var res = await axios.get(serverURL+'/api/Survey');
        var numerics = [];
        var ynNumerics = [];
        var ynTwo = [];
        var comments = [];
        var yn = [];
        console.log(res.data);
        for(let i=0;i<res.data.length;i++){
            if(res.data[i].csq_type.slice(0,1)=='N'){
                if(res.data[i].csq_section==1){
                    answers[res.data[i].csq_id] = {};
                    answers[res.data[i].csq_id].value =null;
                    answers[res.data[i].csq_id].type ='N';
                    answers[res.data[i].csq_id].comment =null;
                    answers[res.data[i].csq_id].public =false;
                    numerics.push(<NumericQuestion ref={(e)=>{refs.current[res.data[i].csq_id]=e}} text={res.data[i].csq_text} range={res.data[i].range} name={'N'+res.data[i].csq_id} key={res.data[i].csq_id} id= {res.data[i].csq_id} update = {(vals)=>{updateValue(res.data[i].csq_id,vals)}} updateComment = {(val)=>{updateComment(res.data[i].csq_id,val)}}/>)
                }
                else if(res.data[i].csq_section==2){
                    answers[res.data[i].csq_id] = {};
                    answers[res.data[i].csq_id].value =null;
                    answers[res.data[i].csq_id].type ='N';
                    answers[res.data[i].csq_id].comment =null;
                    answers[res.data[i].csq_id].public =false;
                    ynNumerics.push(<NumericQuestion ref={(e)=>{refs.current[res.data[i].csq_id]=e}} text={res.data[i].csq_text} range={res.data[i].range} name={'N'+res.data[i].csq_id} key={res.data[i].csq_id} id= {res.data[i].csq_id} update = {(vals)=>{updateValue(res.data[i].csq_id,vals)}} updateComment = {(val)=>{updateComment(res.data[i].csq_id,val)}}/>)
                }
                
            }
            else if(res.data[i].csq_type.slice(0,1)=='Y' && res.data[i].csq_section==1){
                answers[res.data[i].csq_id] = {};
                answers[res.data[i].csq_id].value =null;
                answers[res.data[i].csq_id].type ='Y';
                answers[res.data[i].csq_id].comment =null;
                answers[res.data[i].csq_id].public =false;
                yn.push(<YesNoQuestion ref={(e)=>{refs.current[res.data[i].csq_id]=e}} text={res.data[i].csq_text} name={'Y'+res.data[i].csq_id} key={res.data[i].csq_id} id= {res.data[i].csq_id} update={(vals)=>{updateValue(res.data[i].csq_id,vals)}}/>)
            }
            else if(res.data[i].csq_type.slice(0,1)=='Y' && res.data[i].csq_section==2){
                answers[res.data[i].csq_id] = {};
                answers[res.data[i].csq_id].value =null;
                answers[res.data[i].csq_id].type ='Y';
                answers[res.data[i].csq_id].comment =null;
                answers[res.data[i].csq_id].public =false;
                ynTwo.push(<YesNoQuestion type={2} ref={(e)=>{refs.current[res.data[i].csq_id]=e}} text={res.data[i].csq_text} name={'Y'+res.data[i].csq_id} key={res.data[i].csq_id} id= {res.data[i].csq_id} update={(vals)=>{updateValue(res.data[i].csq_id,vals)}} updateComment = {(val)=>{updateComment(res.data[i].csq_id,val)}}/>)
            }
            else{
                answers[res.data[i].csq_id] = {};
                answers[res.data[i].csq_id].value ='';
                answers[res.data[i].csq_id].type ='C';
                answers[res.data[i].csq_id].comment =null;
                answers[res.data[i].csq_id].public =false;
                comments.push(<CommentQuestion type={(res.data[i].csq_section==2)?(2):(1)} text={res.data[i].csq_text} name={'Y'+res.data[i].csq_id} key={res.data[i].csq_id} id= {res.data[i].csq_id} update={(vals)=>{updateValue(res.data[i].csq_id,vals)}} updateContact = {()=> {}} updatePublic = {(val)=>{updatePublic(res.data[i].csq_id,val)}}/>)
            }
        }
        setNumericQuestions(numerics);
        setYesNoQuestions(yn);
        setYNNumericQuestions(ynNumerics);
        setCommentQuestions(comments);
        setFinalAnswers(answers);
        setYesNoQuestionsSectionTwo(ynTwo);
    }
    const updateContact = function(name,title,email){
        setContactValues([name,title,email]);
    }

    useEffect(()=>{
        initData().then(()=>{
            setLoading(false);
        });
    },[]);

    const updateValue = function(id, value){
        answers[id].value = value;
        setFinalAnswers(answers);
    }

    const updateComment = function(id, value){
        answers[id].comment = value;
        setFinalAnswers(answers);
    }

    const updatePublic = function(id, value){
        answers[id].public= value;
        setFinalAnswers(answers);
    }

    const submitSurvey = async function(){
        console.log(finalAnswers);
        console.log(contactValues);
        if(!validateAnswers()){
            document.getElementById("alerted").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
              });
            return;
        }
        if(mode == 'sample'){
            console.log(finalAnswers);
            return;
        }
        setLoading(true);
        var body = []
        Object.keys(finalAnswers).forEach(function(key) {
                body.push({
                    csd_id:csdId,
                    csq_id:parseInt(key),
                    value:(finalAnswers[key].value).toString(),
                    type:finalAnswers[key].type,
                    comment: finalAnswers[key].comment,
                    publicAnswer: finalAnswers[key].public
                })
         });
        var res = await axios.post(serverURL+'/api/Survey',body);
        var c = await axios.put(serverURL+'/api/Survey/ClientInfo',{
            csd_token: id,
            csd_newname:contactValues[0],
            csd_newtitle:contactValues[1],
            csd_newemail: contactValues[2]
        })
        var email = await axios.get(serverURL+'/api/Email?csdToken='+id);
        console.log('Survey Submitted');
        setComplete(true);
        setLoading(false);
    }

    const validateAnswers = function(){
        var res = true;
        var missing = [];
        Object.keys(finalAnswers).forEach(function(key) {
            if (finalAnswers[key].value == null && finalAnswers[key].type!=='C'){
                res = false;
                missing.push(key);
            }
         });
         if(missing.length !== 0){
            setAlerted(true);
            var nRows = numericQuestions;
            var ynNrows = ynNumericQuestions;
            var yRows = yesNoQuestions;
            var yTwoRows = yesNoQuestionsSectionTwo;
            for(let i = 0;i<nRows.length;i++){
                if(missing.includes((nRows[i].props.id).toString())){
                    refs.current[nRows[i].props.id].switchAlert();
                }
            }
            for(let i = 0;i<yRows.length;i++){
                if(missing.includes((yRows[i].props.id).toString())){
                    refs.current[yRows[i].props.id].switchAlert();
                }
            }
            for(let i = 0;i<ynNrows.length;i++){
                if(missing.includes((ynNrows[i].props.id).toString())){
                    refs.current[ynNrows[i].props.id].switchAlert();
                }
            }
            for(let i = 0;i<yTwoRows.length;i++){
                if(missing.includes((yTwoRows[i].props.id).toString())){
                    refs.current[yTwoRows[i].props.id].switchAlert();
                }
            }
            setNumericQuestions(nRows);
            setYNNumericQuestions(ynNrows);
            setYesNoQuestions(yRows);
            setYesNoQuestionsSectionTwo(yTwoRows);
            prevMissing = missing;
         }
         return res;
    }
    if(noData) return (<div className='container w-50'>
    <div className='row'>
        <div className='col h2 text-center card shadow mt-2'>
            <div className='card-body'>
            Survey Not Found
            </div>
        </div>
    </div>
    </div>);

    if (loading) return <Spinner/>

    if (complete) return(
        <div className='container'>
            <div className='row card shadow mt-3'>
                <div className='col h4 card-body text-center'>
                    Survey Successfully Completed. Thank you!
                </div>
            </div>
        </div>
    );

    if (completed) return(
        <div className='container'>
            <div className='row card shadow mt-3'>
                <div className='col h4 card-body text-center'>
                    Survey Already Completed. Thank you!
                </div>
            </div>
        </div>
    );

    return (
        <div className='m-3'>
            <div className='container-fluid card shadow' style={{width:'60%'}}>
                <div className='row pt-3 pb-3' style={{backgroundColor:'#e3f1f3'}}>
                    <div className='col-2'>
                        <img src='https://www.freese.com/wp-content/uploads/2020/09/logo-dark.svg' className='rounded'></img>
                    </div>
                    <div className='col-8 display-5 text-center'>
                        Project Satisfaction Survey
                    </div>
                    <div className='col-2'></div>
                </div>
                <div className='card-body' id='alerted'>
                    <div className='row'>
                        <div className='col-12'>
                            <ProjectInfo token={mode=='sample'?'sample':id} noData = {()=>{setNoData(true)}} completed = {()=>{setCompleted(true)}} update = {(val)=>{setCsdId(val)}} updateContact = {(name,title,email)=> updateContact(name,title,email)}/>
                        </div>
                    </div>
                    { alerted &&
                        <div className='row'>
                            <div className='col-12 text-center text-danger h6 pb-1'>
                            <i className="bi bi-exclamation-circle"></i> Please Select Scores For Highlighted Questions <i className="bi bi-exclamation-circle"></i> 
                            </div>
                        </div>
                    }
                    {
                        (numericQuestions.length !=0) && 
                        <>
                        <p className='h6'>Please rate our performance in the following key areas:</p>
                            <div className='row'>
                                <div className='col-12 table-responsive'>
                                    <table className='table table-striped table-bordered border-3' >
                                        <thead>
                                            <tr className='table-primary mb-2' style={{backgroundColor:'#e3f1f3'}}>
                                                <th scope='col' className='text-center align-middle' style={{width:'30%',backgroundColor:'#e3f1f3'}}></th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#feaa4e'}}> Poor</th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#f9e233'}}> Fair</th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#f6fc8f'}}> Good</th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#e4f56b'}}> Very Good</th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#cfeb55'}}> Excellent</th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#e3f1f3'}}> Doesn't Apply</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {numericQuestions}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    }
                    {
                        ynNumericQuestions.length != 0 &&
                        <>
                        <hr/>
                            <div className='row'>
                                <div className='col-12 table-responsive'>
                                    <table className='table table-striped table-bordered'  style={{borderColor:'#b3d1ff'}}>
                                        <thead>
                                            <tr className='table-primary mb-2'>
                                                <th scope='col' className='text-center align-middle' style={{width:'30%',backgroundColor:'#e3f1f3'}}></th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#feaa4e'}}> Not at all likely</th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#f9e233'}}> Unlikely</th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#f6fc8f'}}> Neutral</th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#e4f56b'}}> Likely</th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#cfeb55'}}> Extremely Likely</th>
                                                <th scope='col' className='text-center align-middle' style={{width:'12%',backgroundColor:'#e3f1f3'}}> Doesn't Apply</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ynNumericQuestions}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    }
                    {
                        yesNoQuestions.length != 0 &&
                        <>
                            <hr/>
                                <div className='row'>
                                    <div className='col-12 table-responsive'>
                                        <table className='table table-striped table-bordered'  style={{borderColor:'#b3d1ff'}}>
                                        <thead>
                                                <tr className='table-primary mb-2'>
                                                    <th scope='col' className='w-50'></th>
                                                    <th scope='col' className='text-center'> Yes</th>
                                                    <th scope='col' className='text-center'> No</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {yesNoQuestions}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        </>
                    }
                    {
                        commentQuestions.length != 0 &&
                        <>
                            <hr/>
                            <div className='row'>
                                <div className='col-12 table-responsive'>
                                    <table className='table table-bordered'>
                                        <tbody>
                                            {commentQuestions}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    }
                    {
                        yesNoQuestionsSectionTwo.length != 0 &&
                        <>
                        <hr/>
                                <div className='row'>
                                    <div className='col-12 table-responsive'>
                                        <table className='table table-striped table-bordered'  style={{borderColor:'#b3d1ff'}}>
                                        <thead>
                                                <tr className='table-primary mb-2'>
                                                    <th scope='col' className='w-50' style={{backgroundColor:'#e3f1f3'}}></th>
                                                    <th scope='col' className='text-center' style={{backgroundColor:'#e3f1f3'}}> Yes</th>
                                                    <th scope='col' className='text-center' style={{backgroundColor:'#e3f1f3'}}> No</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {yesNoQuestionsSectionTwo}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        </>
                    }
                    <div className='row'>
                        <div className='col text-end'>
                            <button className='btn btn-success' style={{backgroundColor:'#015d91',borderColor:'#015d91'}} onClick={()=>{submitSurvey()}}>Submit Survey<i className="bi bi-arrow-right-square ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
