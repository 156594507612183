import React, { forwardRef,useImperativeHandle } from 'react'
import { useEffect,useState } from 'react';

const YesNoQuestion = forwardRef((props,ref) => {
    const [value,setValue] = useState(null);
    const [alert,setAlert] = useState(false);
    const [showComment,setShowComment] = useState(false);
    const [comment,setComment] = useState('');
    const text = props.text;
    const name = props.name;
    const update = props.update;
    const type = props.type;
    const updateComment = props.updateComment;

    const switchAlert = function(){
        if(value==null){
            setAlert(true);
        }
    }

    useEffect(()=>{
        if(value!=null){
            update(value);
        }
    },[value])

    useEffect(()=>{
        updateComment(comment);
    },[comment])

    useImperativeHandle(ref,()=>({
        switchAlert:switchAlert
    }));

    return (
        <>
        <tr style={alert? {backgroundColor:'#ffe6e6'}:{}}>
            <td className='text-center h6'>{text}</td>
            <td className= 'text-center'>
                <input className="form-check-input" type="radio" name={name} value='1' onClick={()=>{setValue(1); setAlert(false);setShowComment(true)}}/>
            </td>
            <td className= 'text-center'>
                <input className="form-check-input" type="radio" name={name} value='2' onClick={()=>{setValue(2); setAlert(false);setShowComment(false)}}/>
            </td>
        </tr>
        {
            showComment &&
            <tr>
                <td colSpan={3} style={{paddingLeft:'50%',backgroundColor:'#F8F8F8'}}>
                        <textarea className='form-control' type='text' value={comment} placeholder='Please explain the outstanding issues.' onChange={(e)=>{setComment(e.target.value)}}></textarea>
                </td>
            </tr>
        }
        </>
    )
});
export default YesNoQuestion;
