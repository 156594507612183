import React,{useState, useEffect} from 'react'
import $ from 'jquery'

export default function CommentQuestion(props) {
    const text = props.text;
    const id = props.id;
    const type = props.type;
    const [value,setValue] = useState(null);
    const [showShareable,setShowShareable] = useState(false);
    const [isShareable,setIsShareable] = useState(2);
    const update = props.update;
    const updatePublic = props.updatePublic;

    useEffect(()=>{
        if(value != null){
            update(value);
        }
    },[value]);

    useEffect(()=>{
        if(isShareable == 1){
            updatePublic(true);
        }
        else{
            updatePublic(false)
        }
    },[isShareable])

    return (
        <>
        <tr className='' style={{borderColor:'#c9e4e8', borderWidth:'2px'}}>
            <td className='text-center h6 table-primary  align-middle' style={{width:'50%',backgroundColor:'#e3f1f3'}}>
                {text}
            </td>
            <td style={{backgroundColor:'#e3f1f3'}}>
                <textarea className="form-control" rows="3" onChange={(e)=>{
                    setValue(e.target.value);
                    if(e.target.value != '' && type==2){
                        setShowShareable(true);
                    }
                    else{
                        setShowShareable(false);
                    }
                }} id={id}/>
            </td>
        </tr>
        {
            showShareable &&
            <tr style={{borderColor:'#b3d1ff', borderWidth:'2px'}}>
                <td className='text-center h6 table-primary pt-4' style={{width:'50%', backgroundColor:'#f8f8f8'}}>
                    <p className='align-top'>May we use this response in marketing materials?</p>
                </td>
                <td style={{width:'50%', backgroundColor:'#f8f8f8'}} className='align-middle'>
                    <div className='row'>
                        <div className='col text-end h6'>
                            Yes:
                        </div>
                        <div className='col text-start'>
                            <input className="form-check-input" name={text} type="radio" value='1' onClick={()=>{setIsShareable(1)}} />
                        </div>
                        <div className='col text-end h6'>
                            No:
                        </div>
                        <div className='col text-start'>
                            <input className="form-check-input" name={text} type="radio" value='2' onClick={()=>{setIsShareable(2)}} defaultChecked={true}/>
                        </div>
                    </div>

                </td>
            </tr>
        }
        </>
    )
}
