import React, {forwardRef,useImperativeHandle,useState,useEffect} from 'react'
import RadioInput from './RadioInput';

const NumericQuestion = forwardRef((props,ref)=> {
    const [value,setValue] = useState(null);
    const text = props.text;
    const range = parseInt(props.range);
    const [radios,setRadios] = useState([]);
    const [alert,setAlert] = useState(false);
    const [comment,setComment] = useState('');
    const [showComment,setShowComment] = useState(false);
    const name = props.name;
    const update = props.update; 
    const updateComment = props.updateComment;

    const switchAlert = function(){
        if(value==null){
            setAlert(true);
        }
    }

    useImperativeHandle(ref,()=>({
        switchAlert:switchAlert
    }));

    useEffect(()=>{
        let res = [];

        //rest of the values
        for(let i=1; i<=range;i++){
            res.push(<RadioInput name={name} id={name+i} key={name+i} value={i} updateVal = {()=>{
                setValue(i);
                setAlert(false);
                if(i<4){
                    setShowComment(true);
                }
                else{
                    setShowComment(false);
                }
            }
            }/>);
        }

        //create does not apply
        res.push(<RadioInput name={name} id={name+0} key={name+0} value={0} updateVal = {()=>{setValue(0);setAlert(false);setShowComment(false)}}/>);
        setRadios(res);
    },[]);

    useEffect(()=>{
    if(value != null){
        update(value);
    }
    },[value])

    useEffect(()=>{
        updateComment(comment);
    },[comment])

    return (
        <>
            <tr style={alert? {backgroundColor:'#ffe6e6'}:{}}>
                <td>{text}</td>
                {radios}
            </tr>
            {
                showComment &&
                <tr>
                    <td colSpan={7} style={{paddingLeft:'30%'}}>
                        <textarea className='form-control' type='text' value={comment} placeholder='(Optional) Please provide your feedback on how we could do better in this area.' onChange={(e)=>{setComment(e.target.value)}}></textarea>
                    </td>
                </tr>
            }
        </>
    )
});
export default NumericQuestion;
