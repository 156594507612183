import React, {useState,useEffect}from 'react'
import Spinner from './Spinner';
import axios from 'axios';
import $ from 'jquery';
import config from '../config.json'

export default function ProjectInfo(props) {
    const [loading,setLoading] = useState(true); 
    const [projData,setProjData] = useState(null);
    const serverURL = config.serverURL;
    const token = props.token;
    const noData = props.noData;
    const completed = props.completed;
    const update = props.update;
    const updateContact = props.updateContact;

    const initData = async function(){
        if(token =='sample'){
            var res = {};
            res.clientName = 'Michael Scott'
            res.clientTitle = 'Regional Manager'
            res.prj_name = 'Dunder Mifflin Paper Co. Remodel'
            res.pm_emp_name = 'Dwight Schrute'
            res.prj_code = 'SCRA123'
            setProjData(res);
        }
        else{
            try{
                var res = await axios.get(serverURL+'/api/Survey/ProjInfo?token='+token);
                console.log(res.data);
                if(res.data.csdDate != null){
                    setCompleted();
                }
                setProjData(res.data);
                update(res.data.csd_id);
            }
            catch(e){
                setFail();
                console.log(e)
            } 
        }
    }

    const updateWrapper = function(){
        var name = $('#changeName').val();
        var title = $('#changeTitle').val();
        var email = $('#changeEmail').val();
        updateContact(name,title,email);
    }

    useEffect(()=>{
        initData().then(()=>{
            setLoading(false);
        });
    },[]);

    const setCompleted = function(){
        completed();
    }

    const setFail = function(){
        noData();
    }
    
    
    if (loading) return <Spinner/>
    return (
        <>
        <hr/>
        <div className='row'>
            <div className='col-6'>
                <div className='row'>
                    <div className='col-2 h6'>
                        Name:
                    </div>
                    <div className='col '>
                        {projData.clientName}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-2 h6'>
                        Title:
                    </div>
                    <div className='col '>
                        {projData.clientTitle}
                    </div>
                </div>
            </div>
            <div className='col-6'>
                <div className='row'>
                    <div className='col-3 h6'>
                        Project:
                    </div>
                    <div className='col '>
                        {(projData.alt_proj_name != null? projData.alt_proj_name: projData.prj_name)+' ('+projData.prj_code+')'}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-3 h6'>
                        FNI Project Manager:
                    </div>
                    <div className='col '>
                        {projData.pm_emp_name}
                    </div>
                </div>
            </div>
        </div>
        <hr/>
        <div className='row'>
            <p className='h6'>If you are not {projData.clientName}, please provide the following information </p>
            <div className='col h6'>
                <label htmlFor="changeName">Name:</label>
                <input type="text" className="form-control" id='changeName' onChange = {()=>{updateWrapper()}}/>
            </div>
            <div className='col h6'>
                <label htmlFor="changeName">Title:</label>
                <input type="text" className="form-control" id='changeTitle' onChange = {()=>{updateWrapper()}}/>
            </div>
            <div className='col h6'>
                <label htmlFor="changeName">Email:</label>
                <input type="text" className="form-control" id='changeEmail'onChange = {()=>{updateWrapper()}}/>
            </div>
        </div>
        <hr/>
        </>
    )
}
