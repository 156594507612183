import { offset } from '@popperjs/core';
import React from 'react'

export default function RadioInput(props) {
    const name = props.name;
    const id = props.id;
    const value = props.value;
    const update = props.updateVal;

    const valCheck = function(){
        update();
    }

    return (
        <td className= 'text-center'>
            <input className="form-check-input" type="radio" name={name} id={id} value={value} onClick={()=>{valCheck()}}/>
        </td>
    )
}
