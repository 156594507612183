import './App.css';
import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';

import React from 'react'

//pages
import SampleSurvey from './pages/SampleSurvey';
import Survey from './pages/Survey';

export default function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='/:id' element={<Survey/>}/>
            <Route path='/' element={<SampleSurvey/>}/>
        </Routes>
    </BrowserRouter>
  )
}
